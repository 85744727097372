<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="list">
        <div class="item" v-for="(item, index) in data" :key="index" @click="choose_address(item)">
          <div class="item_left">
            <div class="item_title">
              {{ item.address }}
            </div>
            <div class="item_sub">{{ item.recipient }} {{ item.phone }}</div>
          </div>
          <div><div class="item_btn" @click.stop="jump(item)">编辑</div></div>
        </div>
      </div>
      <div class="add_box">
        <router-link
          :to="{
            path: '/edit-address',
          }"
        >
          <div class="add_btn">新增收货地址</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { address_list } from "./service";
export default {
  data() {
    return {
      data: {},
      loading: false,
      order_id: this.$route.query.order_id
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.get_list();
  },
  destroyed() {},
  methods: {
    async get_list() {
      this.loading = true;
      try {
        const { data } = await address_list();
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    jump(item) {
      this.$router.push({
        path: '/edit-address',
        query: {
          id: item.id,
          address: item.address,
          recipient: item.recipient,
          phone: item.phone,
          id_card: item.id_card
        },
      })
    },
    choose_address(item) {
      this.$router.push({
        path: '/anesthesia-result',
        query: {
          address: item.address,
          recipient: item.recipient,
          phone: item.phone,
          id_card: item.id_card,
          order_id: this.order_id
        },
      })
    },
  },
};
</script>

<style scoped >
.g_main_content {
  background: #f7f7f7;
  padding-top: 17px;
}

.list {
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
}

.item {
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #565656;
  border-top: solid 1px rgba(0, 0, 0, 0.04);
}

.item_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px;
}

.item_sub {
  font-size: 14px;
  line-height: 16px;
}

.item_btn {
  width: 60px;
  height: 30px;
  padding: 6px 16px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  background: #0088ff;
  border-radius: 21px;
}

.add_box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 76px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  padding: 10px 21px;
}

.add_btn {
  height: 40px;
  background: #0088ff;
  border-radius: 9px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
}
</style>