import api from '../../../utils/api'

export async function address_list() {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: Math.random() > 0.5?[]:[{
  //         recipient: '小明',
  //         phone: 1383193034,
  //         address: '上海浦东新区东方路1800弄',
  //         house_num: '2003'
  //       }, {
  //         recipient: '大明',
  //         phone: 1388773034,
  //         address: '上海浦东新区东方路800弄',
  //         house_num: '1003'
  //       }]
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/address/list`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}







